import React, { useState } from "react";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import Input from "../input";
import { toast } from "react-toastify";
import APIEnd, { API_KEY } from "../../../../configs/apiEndPoint";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function ChangePassword() {

  const navigate = useNavigate()
  const { width } = useWindowDimensions();

  const { token, logout } = useAuth();

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNew, setConfirmNew] = useState("");

  const handleChangePassword = async () => {
    if (newPass.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    } else if (newPass !== confirmNew) {
      toast.error("Passwords should be thesame");
    }

    try {
      const response = await fetch(`${APIEnd}partner/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_password: currentPass,
          new_password: newPass,
        }),
      });

      const data = await response.json()

      console.log(data)

      if(data.email){
        toast.success("Password Changed!!")
        navigate('/dashboard')
      }else{
        toast.warn("Something went wrong. Try Later")
      }


    } catch (error) {
      console.log("Error:::: ", error)
    }
  };

  return (
    <div
      style={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        padding: "30px 14px",
        borderRadius: 10,
        minWidth: width! < 800 ? "280px" : "400px",
        flex: width! < 800 ? 1 : "normal",
        maxHeight: 500,
        marginBottom: 21,
        justifyContent: "center",
        alignItems: "center",
        gap: 12,
      }}
    >
      <h3 style={{ color: "green" }}>Change your Password</h3>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Input
          disabled={false}
          inputValue={currentPass}
          onChangeFunc={(e: any) => {
            setCurrentPass(e.target.value);
          }}
          placeholder={""}
          title={"Current Password"}
          type={"text"}
          key={25}
        />
        <Input
          disabled={false}
          inputValue={newPass}
          onChangeFunc={(e: any) => {
            setNewPass(e.target.value);
          }}
          placeholder={""}
          title={"New Password"}
          type={"Password"}
          key={27}
        />
        <Input
          disabled={false}
          inputValue={confirmNew}
          onChangeFunc={(e: any) => {
            setConfirmNew(e.target.value);
          }}
          placeholder={""}
          title={"Confirm Password"}
          type={"Password"}
          key={23}
        />
      </div>
      <button
        style={{ backgroundColor: "green", width: 122 }}
        onClick={handleChangePassword}
      >
        Update
      </button>
    </div>
  );
}

export default ChangePassword;
