import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useAuth } from "../../context/AuthContext";
import { useVisibility } from "../../context/VisibilityContext";
import { FaTimes } from "react-icons/fa";
import useWindowDimensions from "../../hooks/useWindowDimension";

const Sidebar = () => {
  const { isVisible, toggleVisibility } = useVisibility();

  const { width } = useWindowDimensions();

  const { logout, setToken } = useAuth();
  return (
    <Drawer
      variant="permanent"
      sx={{
        // width:isVisible === true? "900px":  sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isVisible === true ? "200px" : sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          // backgroundColor: "white",
          color: colorConfigs.sidebar.color,
          // color: "#333",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <List disablePadding>
        <Toolbar
          sx={{
            marginBottom: "20px",
            marginTop: "",
    
          }}
        >
          <Stack
            sx={{ width: "100%",  }}
            direction="row"
            justifyContent="center"
          >
            {width! < 840 ? (
              <Avatar
                sx={{
                  position: "absolute",
                  width: 90,
                  height: 70,
                  marginBottom: 200,
                }}
                src={assets.images.logo}
              />
            ) : (
              <Avatar
                sx={{
                  position: "relative",
                  // left: "30%",
                  width: "7vw",
                  height: "7vw",
                }}
                src={assets.images.logo}
              />
            )}

            {width! < 840 && (
              <button
                onClick={toggleVisibility}
                style={{
                  backgroundColor: "transparent",
                  color: "#333",
                  position: "relative",
                  right: -140,
                }}
              >
                <FaTimes size={18} />
              </button>
            )}
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </List>
      <button
        style={{
          marginTop: 70,
          width: "50%",
          position: "relative",
          left: 20,
          bottom: 10,
          backgroundColor: "green",
        }}
        onClick={() => {
          localStorage.clear();
          logout();
        }}
      >
        LogOut
      </button>
    </Drawer>
  );
};

export default Sidebar;
