import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";
import { useAuth } from "../../context/AuthContext";
import { useVisibility } from "../../context/VisibilityContext";
import useWindowDimensions from "../../hooks/useWindowDimension";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { width } = useWindowDimensions();
  const {
    isVisible,
    toggleVisibility,
    containsWord,
    updateCurrentUrlVal,
    removeCenter,
  } = useVisibility();
  
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <ListItemButton
    
      onClick={() => {

        if (width! < 800) {
          toggleVisibility();
        }

      }}

      component={Link}
      to={item.path}
      sx={{
        "&: hover": {
          backgroundColor: colorConfigs.sidebar.hoverBg,
        },
        backgroundColor:
          appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
        paddingY: "12px",
        paddingX: "24px",
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
