import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";

type PartnerInformationType = {
  id: number;
  full_name: string;
  wallet: number;
  total_made: number;
  is_varified: boolean;
  promo_code: string | number;
  created_at: string;
  modified_at: string;
  user: number;
  username: string;
  email: string;
  phone: number;
  gender: null | string;
  picture: string | null;                                                                             
  ref_count: number
};

export type ReferalsType = {
  id: number | string, name: string, session: string, date: string
}

type ReferalsTypeInfo = {
  count: number |  null,
  next: null,
  previous: null,
  results: ReferalsType[] | null
}


interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  token: string;
  setToken: (value: any) => void
  partnerInformation: PartnerInformationType | null
  setPartnerInformation: (partnerInfo: PartnerInformationType) => void
  referals: ReferalsTypeInfo[]  | null,
  setReferals: (referalList: ReferalsTypeInfo[]) => void
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  login: () => { },
  logout: () => { },
  token: "",
  setToken: () => { },
  partnerInformation: { full_name: '', created_at: "", email: "", gender: "", id: 0, is_varified: false, modified_at: "", phone: 1, picture: "", promo_code: "", total_made: 0, user: 0, username: "", wallet: 0, ref_count: 0 },
  setPartnerInformation: (): PartnerInformationType | any => { },
  setReferals: (): ReferalsType | any => { },
  referals: []
});

type AuthContextProviderType = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthContextProviderType) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState("")

  const [partnerInformation, setPartnerInformation] = useState<PartnerInformationType | null>(null);

  const [referals, setReferals] = useState<ReferalsTypeInfo[] | null>(null)



  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };


  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token, setToken, partnerInformation, setPartnerInformation, referals, setReferals }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
