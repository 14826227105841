import React, {createContext, useContext, useState} from "react";


const VisibilityContext = createContext()

export const VisibilityProvider = ({children}) => {
    const [isVisible, setIsVisible] = useState(false)

    const [containsWord, setContainsWord] = useState(false);


    const toggleVisibility = () =>{
        setIsVisible(!isVisible)
    }

    const currentURL = window.location.href;


    const updateCurrentUrlVal = () => {
       setContainsWord(true)
    };

    const removeCenter = ()=> {
        setContainsWord(false)
    }


    return(
        <VisibilityContext.Provider value={{isVisible, toggleVisibility, updateCurrentUrlVal, containsWord, removeCenter}}>
            {children}
        </VisibilityContext.Provider>
    )
}

export const useVisibility = ()=>{
    return useContext(VisibilityContext)
}