import { AppBar, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import img from "../../../src/assets/images/download.jpg";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useVisibility } from "../../context/VisibilityContext";
import { FaBars } from "react-icons/fa";
import ProfileHover from "../profileHover/ProfileHover";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Topbar = () => {
  const { width } = useWindowDimensions();

  const {partnerInformation} = useAuth()

  const navigate = useNavigate()

  const { isVisible, toggleVisibility } = useVisibility();

  return (
    <AppBar
      position="fixed"
      sx={{
        // width: `calc(98.3% - ${sizeConfigs.sidebar.width})`,
        // width: `calc(98.3% - ${sizeConfigs.sidebar.width})`,
        width:
          width! <= 840 ? `97%` : `calc(98.3% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        display: "flex",
        marginLeft: 1,
        marginTop: 1,
        marginRight: 1,
        borderRadius: 5,
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          gap: 3,
          justifyContent: width! < 840 ? 'space-between' : "flex-end",
          alignItems: "center",
          width: "100%",
          paddingTop: 1,
        }}
      >
        {width! < 840 && (
          <button
            onClick={toggleVisibility}
            style={{ backgroundColor: "transparent" }}
          >
            <FaBars size={22} color="#333" />{" "}
          </button>
        )}
        <div>
          <img
            alt="img"
            onClick={()=>{
              navigate('user-profile')
            }}
            src={partnerInformation?.picture ? partnerInformation.picture : img}
            style={{
              width: 50,
              height: 50,
              padding: 1,
              borderRadius: 25,
              border: "2px solid lightgreen",
              cursor: "pointer"
            }}
          />
        </div>
      </Toolbar>

    </AppBar>
  );
};

export default Topbar;
