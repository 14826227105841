import axios from "axios";
import { Dispatch, SetStateAction, createContext, useState } from "react";

type PartnerInformationType = {
  id: number;
  full_name: string;
  wallet: number;
  total_made: number;
  is_varified: boolean;
  promo_code: string | null;
  created_at: string;
  modified_at: string;
  user: number;
  username: string;
  email: string;
  phone: number;
  gender: null | string;
  picture: string | null;
};

export type UserContextType = {
  user: any;
  setUser: any;
};

type UserContextProviderType = {
  children: React.ReactNode;
};

export const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({ children }: UserContextProviderType) => {
  const [user, setUser] = useState<PartnerInformationType | null>(null);
  const [token, setToken] = useState<PartnerInformationType | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
