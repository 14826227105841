import React from "react";
import "./style.css";

function Input({
  disabled,
  placeholder,  
  title,
  inputValue,
  onChangeFunc,
  type,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 6,  }}>
      <span style={{ fontSize: 15, fontWeight: "400", color: "#222" }}>
        {title}
      </span>
      <input
      
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={inputValue}
        style={{
          padding: 6,
          fontSize: 14,
          color: disabled ? "#555" : "black",
          marginBottom: 20,
          backgroundColor: disabled && "#dce2e2",
        }}
        onChange={onChangeFunc}
        autoComplete="new-password"
      />
    </div>
  );
}

export default Input;
