import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    // bg: "#233044",
    bg: "#fff",
    color: "#333",
    hoverBg: "#8ff78f",
    activeBg: "#8ff78f",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["200"],
};

export default colorConfigs;
