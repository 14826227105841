import React from "react";
import { useAuth } from "../../../context/AuthContext";
import Picture from "../../../assets/images/download.jpg";

function ProfileImage({ selectedImage }) {
  const { partnerInformation } = useAuth();
  return (
    <div>
      {!partnerInformation?.picture ? (
        <img
          src={selectedImage? selectedImage: Picture}
          alt="test"
          style={{
            width: 90,
            height: 90,
            padding: 1,
            borderRadius: "50%",
            border: "2px solid green",
            cursor: "pointer",
          }}
        />
      ) : (
        <img
          alt="userimage"
          src={selectedImage ? selectedImage : partnerInformation?.picture}
          style={{
            width: 90,
            height: 90,
            padding: 1,
            borderRadius: "50%",
            border: "2px solid green",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
}

export default ProfileImage;
