import { Outlet, useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import useWindowDimensions from "../../hooks/useWindowDimension";
// import BottomBar from "../common/BottomBar";
import { useVisibility } from "../../context/VisibilityContext";
// import ProfileHover from "../profileHover/ProfileHover";
import { useEffect } from "react";

const MainLayout = () => {
  const { width } = useWindowDimensions();

  const location = useLocation();

  const { isVisible, containsWord, updateCurrentUrlVal, removeCenter } =
    useVisibility();

  const centerItem = () => {
    if (window.location.href.includes("user")) {
      updateCurrentUrlVal();
      return;
    } else if (!window.location.href.includes("user")) {
      removeCenter();
      return;
    }
  };

  useEffect(() => {
    centerItem();
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar />

      {width! < 840 && isVisible === false ? (
        <></>
      ) : (
        <Box
          component="nav"
          sx={{
            width: isVisible === true ? "200px" : sizeConfigs.sidebar.width,
            flexShrink: 0,
          }}
        >
          <Sidebar />
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pr: 5,
          width:
            width! < 840 ? "100%" : `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
          overflowY: "auto",
          maxHeight: "auto",
          display: "flex",
          justifyContent: "center",
          paddingTop: 11,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
