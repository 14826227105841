import React, { useContext } from "react";
import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.png";

type Props = {};

const LoginPage = (props: Props) => {
  const { login, setToken } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [seePassword, setSeepassword] = useState(false);

  // navigate
  const navigateForgot = () => {
    navigate("/forgot-password");
  };

  // LOGIN USER FUNCTION
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);

    e.preventDefault();

    // Handle form submission (you can replace this with your logic)

    try {
      if (!username || !password) {
        toast.error("All fields are required");
        setLoading(false);
        return;
      }

      fetch(`${APIEnd}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data: any) => {
          if (data.access) {
            login();
            localStorage.setItem("accessToken", data.access);
            localStorage.setItem("refreshToken", data.refresh);
            setToken(data.access);
            navigate("/dashboard");
          } else {
            toast.error("Credentiails Not Correct");
          }
        });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Unknown Eror Try Later");
    }
  };

  return (
    <div className="App" style={{ backgroundColor: "#3a663a" }}>
      <form onSubmit={handleSubmit}>
        <img
          src={logo}
          alt="Avisa"
          style={{
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            width: 152,
            height: 150,
          }}
        />

        <h3
          style={{
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            fontWeight: "400",
          }}
        >
          Sign In
        </h3>

        <span
          style={{
            marginTop: 1,
            fontWeight: "lighter",
            fontSize: 12,
            color: "#333",
            marginBottom: 5,
          }}
        >
          Username
        </span>

        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />

        <span
          style={{
            marginTop: 10,
            fontWeight: "lighter",
            fontSize: 12,
            color: "#333",
            marginBottom: 5,
          }}
        >
          Password
        </span>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            style={{
              flex: 1,
              paddingRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            type={seePassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <button
            type="button"
            style={{
              position: "relative",
              background: "transparent",
              border: "1px solid #bbbfbe",
              top: -5,
              padding: 10,
              borderRadius: 0,
            }}
            onClick={() => {
              setSeepassword(!seePassword);
            }}
          >
            <FontAwesomeIcon
              icon={seePassword ? faEyeSlash : faEye}
              color="grey"
            />
          </button>
        </div>

        <button
          type="submit"
          disabled={loading}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "cnter",
            backgroundColor: "green",
            marginTop: 6,
            marginBottom: 10,
          }}
        >
          {loading ? <div className="loader"></div> : "Login"}
        </button>
        {/* forgot password button */}
        <button
          type="button"
          onClick={navigateForgot}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "cnter",
            backgroundColor: "#e01818",
            marginTop: 6,
            marginBottom: 10,
          }}
        >
          Forgot Password
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
