import React, { useEffect, useMemo, useState } from "react";
import Table from "../../components/table/Table";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { ReferalsType, useAuth } from "../../context/AuthContext";

type Props = {};

const InstallationPage = (props: Props) => {
  const {
    setToken,
    token,
    setPartnerInformation,
    partnerInformation,
    referals,
    setReferals,
  } = useAuth();

  const [referalList, setReferalList] = useState<string | null>(null);

  type PartnerInformationType = {
    id: number;
    full_name: string;
    wallet: number;
    total_made: number;
    is_varified: boolean;
    promo_code: string | null;
    created_at: string;
    modified_at: string;
    user: number;
    username: string;
    email: string;
    phone: number;
    gender: null | string;
    picture: string | null;
  };

  ////////////////       GET PARTNER INFORMATION          /////////////////////////////////

  async function getPartnerInfo() {
    try {
      const response = await fetch(`${APIEnd}partner/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      // console.log("PARTNER INFORMATION", data);
      if (data.detail) {
        await refreshTokenFunc();
      } else {
        setPartnerInformation(data);
        // console.log(referals);
      }
      // setInterval(getReferalList, 10000)
      return partnerInformation;
    } catch (error) {
      console.log("ERROR GETTING INFORMATION ABOUT PARTNERS", error);
    }
  }

  // //////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////

  ///  GET LIST OF ALL REFERALS ///////////////////////////////
  const getReferalList = async () => {
    try {
      const response = await fetch(`${APIEnd}/partner/referal/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.detail) {
        await refreshTokenFunc();
      } else {
        // Inside the else block of getReferalList function
        localStorage.setItem("referals", JSON.stringify(data));
        const referals2 = JSON.parse(`${localStorage.getItem("referals")}`);
      }
    } catch (error) {
      console.log("ERROR GETTING INFORMATION ABOUT PARTNERS", error);
    }
  };

  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////
  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////
  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////
  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////
  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////
  // ////////////  REFRESH REFERRAL TOKEN  ////////////////////////////////////

  // function to get new Access Token
  const refreshTokenFunc = async () => {
    const localRefreshToken = localStorage.getItem("refreshToken");

    if (!localRefreshToken) {
      return;
    }

    // console.log("THIS IS REFRESH TOKEN :::", localRefreshToken);
    if (localRefreshToken) {
      const url = `${APIEnd}login/refresh/`;

      try {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${API_KEY}`,
          },
          body: JSON.stringify({
            refresh: localRefreshToken,
          }),
        })
          .then((response) => {
            return response.json();
            
          })
          .then((data: any) => {
            if (data.access) {
              setToken(data.access);
              localStorage.setItem("newAccess", JSON.stringify(data.access));
              localStorage.setItem("accessToken", JSON.stringify(data.access));
            } else {
            
            }
          });
      } catch (error) {
        console.log("THIS IS REFRESH ERROR", error);
      }
    }
  };

  const memoRizedValue = useMemo(() => {
    return getPartnerInfo();
  }, [token]);

  // const referelValue = useMemo(()=>{return getReferalList()}, [token])

  useEffect(() => {
    // console.log(memoRizedValue);
    getReferalList();
  }, [referals]);

  return (
    <div style={{ width: "99%", overflowY: "auto" }}>
      <div
        style={{
          backgroundColor: "#adf4ad",
          color: "green",
          fontSize: 15,
          borderRadius: 8,
        }}
      >
        <p style={{ margin: 12, padding: 10 }}>
          Hi{" "}
          <span style={{ fontWeight: "bolder", fontSize: 14 }}>
            {" "}
            ${partnerInformation?.full_name}!
          </span>{" "}
          Your promo code is
          <span style={{ fontWeight: "bolder", fontSize: 14 }}>
            {" "}
            ${partnerInformation?.promo_code}.
          </span>{" "}
          Share it with your audience and track all transactions made with this
          code and earn commissions on every sale.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          maxHeight: 350,
          flexWrap: "wrap",
          marginBottom: "50px",
          justifyContent: "center",
        }}
      >
        <InfoBoxes name="WALLET" value={partnerInformation?.wallet} />
        <InfoBoxes name="REFERALS" value={partnerInformation?.ref_count} />
        <InfoBoxes name="PROMO CODE" value={partnerInformation?.promo_code} />
      </div>

      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          marginTop: 15,
          width: "100%",
          borderRadius: 7,
          boxShadow: "rgba(99, 99, 99, 0.2) 5px 12px 18px 9px",
        }}
      >
        <Table />
      </div>
    </div>
  );
};

export default InstallationPage;

///////////////////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////
type InfoBoxProps = {
  name?: string;
  value?: string | number;
};

const InfoBoxes = (props: InfoBoxProps) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        backgroundColor: "#fff",
        borderRadius: 5,
        margin: 10,
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 6,
        gap: 25,
        minWidth: 300,
        maxWidth: 330,
        minHeight: 90,
        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <div
        style={{
          minWidth: "3vw",
          borderRadius: 4,
          backgroundColor: "green",
          color: "white",
          fontWeight: "600",
          fontSize: "25px",
          paddingLeft: 9,
          paddingRight: 20,
          whiteSpace: "nowrap",
        }}
      >
        {props.name}
      </div>
      <div
        style={{
          color: "#333f22",
          fontWeight: "600",
          fontSize: "25px",
          paddingLeft: 9,
          paddingRight: 20,
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {props.value}
      </div>
    </div>
  );
};
