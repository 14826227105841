import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

function RefreshButton({ onClick }) {
  return (
    <button onClick={onClick} style={{backgroundColor: "green"}}>
      <FontAwesomeIcon icon={faSync} />
    </button>
  );
}

export default RefreshButton;
